<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.detail") }}:
        <span class="error--text">{{ total }} đơn</span>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="success"
          class="pr-0"
          text
          @click="exportExcel"
          v-if="items.length > 0"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.excel") }}
        </v-btn>
        <v-btn small color="red darken-1" text @click="cancel">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-simple-table
            fixed-header
            height="calc(100vh - 245px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <!--<th role="columnheader" class="text-center">TT</th>-->
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 65px"
                  >
                    {{ $t("labels.warehouse") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.tracking") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.customer") }}
                  </th>
                  <!--<th role="columnheader" class="text-center">Mã đơn khách</th>-->
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.e_market") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.shop") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 65px"
                  >
                    {{ $t("labels.delivery_company") }}
                  </th>
                  <!--<th role="columnheader" class="text-center">Mã VC</th>-->
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    <template
                      v-if="['thieu_hang', 'cho_ma_van_chuyen'].includes(state)"
                      >Tạo</template
                    >
                    <template v-else>{{
                      $t("labels.order_status_40")
                    }}</template>
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.warehouse_state") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.delivery_state") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 105px"
                  >
                    {{ $t("labels.product") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_get_stamp',
                          'warehouse_admin',
                        ]) &&
                        canGetEMarketStamp.length > 0 &&
                        state === 'thieu_tem'
                      "
                      small
                      color="success"
                      class="mr-1"
                      @click="getEMarketStamp(canGetEMarketStamp)"
                      >{{ $t("labels.get_e_market_stamp") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) &&
                        canChangeDelivery.length > 0 &&
                        checkedIds.length > 0 &&
                        idWarehouse
                      "
                      small
                      color="warning"
                      class="mr-1"
                      @click="showChangeDelivery"
                      >{{ $t("labels.change_delivery") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) &&
                        canReGetDelivery.length > 0 &&
                        state === 'cho_ma_van_chuyen'
                      "
                      small
                      color="blue lighten-1"
                      class="white--text"
                      @click="reGetDelivery(canReGetDelivery)"
                      >{{ $t("labels.get_delivery") }}</v-btn
                    >
                  </th>
                  <th role="columnheader" class="text-center">
                    <v-checkbox
                      v-if="
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) &&
                        canChangeDelivery.length > 0 &&
                        idWarehouse
                      "
                      v-model="checkedAll"
                      :value="true"
                      @change="onCheckAllChange"
                    ></v-checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id" class="text-center">
                  <!--<td>{{ key + 1 }}</td>-->
                  <td>{{ item.warehouse_code }}</td>
                  <td>
                    <OrderTracking :tracking-id="item.tracking_id" />
                  </td>
                  <td>{{ item.company_name }}</td>
                  <!--<td>{{ item.customer_order_id }}</td>-->
                  <td>{{ item.e_market_name }}</td>
                  <td>{{ item.customer_e_market_shop_name }}</td>
                  <td>{{ item.delivery_company }}</td>
                  <!--<td>
                  <a v-if="item.delivery_tracking_link" :href="urlTrackingDelivery(item)" target="_blank">{{ item.delivery_order_id }}</a>
                  <span v-else>{{ item.delivery_order_id }}</span>
                </td>-->
                  <td>
                    {{
                      item.receipt_at
                        ? formatDateTime(item.receipt_at)
                        : formatDateTime(item.create_time)
                    }}
                  </td>
                  <td>
                    {{ item.goods_issue_state_name }}
                    <div style="font-size: 11px; color: #666">
                      <span v-if="item.state === 20 || item.state === 35">{{
                        formatDateTime(item.create_time)
                      }}</span>
                      <span v-else-if="item.state === 40">{{
                        formatDateTime(item.receipt_at)
                      }}</span>
                      <span v-else-if="item.state === 60">{{
                        formatDateTime(item.handling_at)
                      }}</span>
                      <span v-else-if="item.state === 100">{{
                        formatDateTime(item.pickup_at)
                      }}</span>
                      <span v-else-if="item.state === 160">{{
                        formatDateTime(item.package_at)
                      }}</span>
                      <span v-else-if="item.state === 200">{{
                        formatDateTime(item.hand_over_at)
                      }}</span>
                      <span v-else-if="item.state === 220">{{
                        formatDateTime(item.confirm_return_at)
                      }}</span>
                      <span v-else-if="item.state === 240">{{
                        formatDateTime(item.receipt_return_at)
                      }}</span>
                    </div>
                  </td>
                  <td>
                    {{ item.delivery_state_name }}
                    <div
                      v-if="item.last_delivery_state_updated"
                      style="font-size: 11px; color: #666"
                    >
                      {{ formatDateTime(item.last_delivery_state_updated) }}
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="(jtem, jdex) in item.items"
                      :key="`${jdex}_${item.tracking_id}_${jtem.id_goods}`"
                      :class="{
                        'error--text':
                          item.missing_items.includes(
                            jtem.customer_goods_barcode
                          ) && item.state === 20,
                      }"
                    >
                      {{ jtem.sku }} x {{ jtem.quantity }}
                    </div>
                  </td>
                  <td>
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_get_stamp',
                          'warehouse_admin',
                        ]) &&
                        state === 'thieu_tem' &&
                        canGetEMarketStamp.includes(item.id)
                      "
                      @click="getEMarketStamp([item.id])"
                      x-small
                      color="success"
                      class="mr-1"
                      >{{ $t("labels.get_e_market_stamp") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) && canChangeDelivery.includes(item.id)
                      "
                      x-small
                      color="warning"
                      class="mr-1"
                      @click="showChangeDeliveryByItem(item.id)"
                      >{{ $t("labels.change_delivery") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) && canReGetDelivery.includes(item.id)
                      "
                      x-small
                      color="blue lighten-1"
                      class="white--text"
                      @click="reGetDelivery([item.id])"
                      >{{ $t("labels.get_delivery") }}</v-btn
                    >
                  </td>
                  <td>
                    <v-checkbox
                      v-if="
                        canChangeDelivery.includes(item.id) &&
                        checkPermission([
                          'goods_issue_update_delivery',
                          'warehouse_admin',
                        ]) &&
                        idWarehouse
                      "
                      v-model="checkedIds"
                      :value="item.id"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="pt-3">
            <v-pagination
              v-model="page"
              :length="totalPage"
              :total-visible="6"
            ></v-pagination>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogUpdateDelivery" persistent max-width="350px">
      <UpdateDeliveryCompany
        v-if="dialogUpdateDelivery"
        :order-ids="checkedIds"
        :order-id="checkedId"
        :id-warehouse="idWarehouse"
        @cancel="hideChangeDelivery"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
    UpdateDeliveryCompany: () =>
      import("@/components/goods_issue/UpdateDeliveryCompany"),
  },
  name: "StatisticalDetail",
  props: {
    idWarehouse: {
      type: [String, Number],
      default: () => null,
    },
    idPos: {
      type: [String, Number],
      default: () => null,
    },
    idDeliveryCompany: {
      type: [String, Number],
      default: () => null,
    },
    state: {
      type: [String, Number],
      default: () => null,
    },
    date: {
      type: [String, Array],
      default: () => null,
    },
    idOrders: {
      type: [String, Array],
      default: () => null,
    },
    type: {
      type: String,
      default: () => "warehouse",
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    total: 0,
    items: [],
    checkedAll: false,
    checkedIds: [],
    checkedId: null,
    filters: {},
    isLoading: false,
    dialogUpdateDelivery: false,
  }),
  computed: {
    canChangeDelivery() {
      return [...this.items]
        .filter(
          (i) =>
            [1, 2, 5].includes(i.create_method) &&
            i.id_delivery_contract &&
            i.state >= 35 &&
            i.state < 200
        )
        .map((i) => i.id);
    },
    canReGetDelivery() {
      return [...this.items]
        .filter(
          (i) =>
            [1, 2, 5].includes(i.create_method) &&
            i.id_delivery_contract &&
            i.state === 35
        )
        .map((i) => i.id);
    },
    canGetEMarketStamp() {
      return [...this.items]
        .filter(
          (i) =>
            [3, 6].includes(
              i.create_method
            ) /*  && i.e_market_name.toLowerCase() !== 'giữ tồn' */
        )
        .map((i) => i.id);
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    onCheckAllChange() {
      let checkedIds = [];
      if (this.checkedAll) {
        checkedIds = [...this.canChangeDelivery];
      }
      this.checkedIds = [...checkedIds];
    },
    getList: debounce(function () {
      this.checkedId = null;
      this.checkedIds = [];
      this.checkedAll = null;

      const url =
        this.type === "pos"
          ? "/pos/v1/statistical-detail"
          : "/dashboard/v1/statistical-detail";
      httpClient
        .post(url, {
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
          id_delivery_company: this.idDeliveryCompany,
          state: this.state,
          date: this.date,
          id_orders: this.idOrders,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.items = [...data.rows].map((r) => {
            const splItems = r.items ? r.items.split(";") : [];
            const oItems = [];
            for (let i = 0; i < splItems.length; i++) {
              const jtems = splItems[i];
              const [id_goods, sku, customer_goods_barcode, quantity] =
                jtems.split("Ω");
              oItems.push({ id_goods, sku, customer_goods_barcode, quantity });
            }
            r.items = [...oItems];
            r.missing_items = r.missing_items ? r.missing_items.split(";") : [];
            return r;
          });
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = `${this.state}.xlsx`;

      try {
        await this.downloadExcelFile(
          "/dashboard/v1/export-statistical-detail",
          {
            id_warehouse: this.idWarehouse,
            id_delivery_company: this.idDeliveryCompany,
            state: this.state,
            date: this.date,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    urlTrackingDelivery(item) {
      let tracking_link = item.delivery_tracking_link || "";
      if (tracking_link && tracking_link.includes("[delivery_order_id]")) {
        return tracking_link.replace(
          "[delivery_order_id]",
          item.delivery_order_id
        );
      } else {
        return `${tracking_link + item.delivery_order_id}`;
      }
    },

    showChangeDeliveryByItem(id) {
      this.checkedId = id;
      this.showChangeDelivery();
    },
    showChangeDelivery() {
      this.dialogUpdateDelivery = true;
    },
    hideChangeDelivery() {
      this.dialogUpdateDelivery = false;
      this.checkedId = null;
    },
    async reGetDelivery(ids) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const orderIds = [...ids];
      try {
        await httpClient.post(
          "/goods-issue-detail/v1/re-get-delivery-order-id",
          {
            orderIds,
          }
        );
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(
          this.$t("messages.request_re_get_delivery_success")
        );
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    async getEMarketStamp(ids) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const orderIds = [...ids];
      try {
        await httpClient.post(
          "/goods-issue-detail/v1/get-e-market-stamp-file",
          {
            orderIds,
          }
        );
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(
          this.$t("messages.request_get_e_market_stamp_success")
        );
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
